import React from 'react'
import ElementImage from '@components/global/element/Image'

import * as cx from './IDXSolutions.module.scss'
import clsx from 'clsx'

const IDXSolutions = ({ images, icons, bg }) => {
  return (
    <React.Fragment>
      <section id="idx-solutions" className={`${cx.section}`}>
        <ElementImage className={cx.background} src={bg.bg1} alt={'BG'} />
        <div className={`${cx.container} container`}>
          <div className={cx.heading}>
            <h2 className="heading-2">IDX Solutions</h2>
            <p className={`${cx.subtitle} subtitle-1`}>FOR AGENT AND BROKERS</p>
          </div>
          <ul className={cx.list}>
            <li className={cx.itemWrapper}>
              <div className={cx.item}>
                <div>
                  <ElementImage src={images.idx1} alt={'Device 1'} />
                </div>
                <div className={cx.content}>
                  <h2 className={'subtitle-2'}>What is IDX?</h2>
                  <p className={'subtitle-5'}>
                    {`IDX, or Internet Data Exchange, is a policy that allows real estate professionals to share property listing data across their respective websites. Established by the National Association of Realtors (NAR), IDX uses information from local Multiple Listing Service (MLS) databases to feed comprehensive and up-to-date listings pages on agent and brokerage websites that cover a common area.`}
                  </p>
                </div>
              </div>
            </li>
            <li className={cx.itemWrapper}>
              <div className={cx.item}>
                <div>
                  <ElementImage src={images.idx2} alt={'Device 2'} />
                </div>
                <div className={cx.content}>
                  <h2 className={'subtitle-2'}>How does IDX work?</h2>
                  <p className={'subtitle-5'}>
                    By consolidating area-wide listing data into a
                    comprehensive, real-time data feed, IDX provides all real
                    estate stakeholders with advantages that maximize the speed,
                    accessibility, and robustness of an online platform.
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section id="idx-solutions" className={`${cx.section} ${cx.bot}`}>
        <ElementImage className={cx.background} src={bg.bg2} alt={'BG'} />
        <div className={`${cx.container} container`}>
          <ul>
            <li className={cx.itemWrapper}>
              <div className={cx.item}>
                <div>
                  <ElementImage src={images.idx3} alt={'Device 3'} />
                </div>
                <div className={cx.content}>
                  <div className={cx.wIcons}>
                    <div className={cx.icon}>
                      <ElementImage
                        src={icons.seller}
                        alt={'Seller Icon'}
                        className={cx.imagecon}
                      />
                    </div>
                    <div className={cx.iContent}>
                      <h2 className={'subtitle-2'}>For Sellers:</h2>
                      <p className={'subtitle-5'}>
                        {`IDX allows property sellers’ listings to gain exposure on multiple websites, significantly expanding their opportunities to find serious buyers from several markets.`}
                      </p>
                    </div>
                  </div>
                  <div className={cx.wIcons}>
                    <div className={cx.icon}>
                      <ElementImage
                        src={icons.buyer}
                        alt={'Buyer Icon'}
                        className={cx.imagecon}
                      />
                    </div>
                    <div className={cx.iContent}>
                      <h2 className={'subtitle-2'}>For Buyers:</h2>
                      <p className={'subtitle-5'}>
                        {`IDX makes searching for property faster and convenient by placing relevant listings from any given area – regardless of the listing agent – in one place.`}
                      </p>
                      <p className={'subtitle-5'}>
                        Homebuyers also benefit from robust features such as the
                        ability to save searches, mark their favorite
                        properties, and share listings on their social media
                        networks.
                      </p>
                    </div>
                  </div>
                  <div className={cx.wIcons}>
                    <div className={cx.icon}>
                      <ElementImage
                        src={icons.agent}
                        alt={'Agent Icon'}
                        className={cx.imagecon}
                      />
                    </div>
                    <div className={cx.iContent}>
                      <h2 className={'subtitle-2'}>
                        For Real Estate Professionals:
                      </h2>
                      <p className={'subtitle-5'}>
                        {`By connecting to an area-wide listing database, IDX turns real estate websites into reliable one-stop hubs where users – whether intending to buy or sell property – can find the resources they need. `}
                      </p>
                      <p className={'subtitle-5'}>
                        By offering an excellent customer experience on their
                        websites, agents and brokers can build their brand as
                        industry experts and trustworthy authorities for local
                        area information.
                      </p>
                      <p className={'subtitle-5'}>
                        With carefully crafted tools and features, the user
                        traffic that these IDX-driven resources generate can be
                        turned into strong leads and full-fledged clients.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </React.Fragment>
  )
}

export default IDXSolutions

import React from 'react'
import { Link } from 'gatsby'
import ElementImage from '@components/global/element/Image'
import * as cx from './RealEstateWebsites.module.scss'
import useIDXWebsitesMetadata from '../../../../hooks/idx-websites-metadata'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import useMedia, { media } from '@hooks/useMedia'
import { isRegExp } from 'lodash'

const RealEstateWebsites = () => {
  const { Ipad } = useIDXWebsitesMetadata()
  const isTablet = useMedia(media.tablet)

  return (
    <section id="real-estate-websites" className={`${cx.section}`}>
      <div className={`${cx.container} container`}>
        <div className={cx.heading}>
          <h2 className="heading-5">IDX Real Estate Websites</h2>
          <p className={`${cx.customp} default-body large`}>
            {!isTablet ? (
              <>
                Do you want to search the MLS directly on your website? Agent
                Image&apos;s IDX Studio allows you to capture leads, save
                property searches, and send
                <br />
                email updates to your clients.
              </>
            ) : (
              <>
                Do you want to search the MLS directly on your website?
                <br />
                Agent Image&apos;s IDX Studio allows you to capture leads, save
                property
                <br />
                searches, and send email updates to your clients.
              </>
            )}
          </p>
          <Link to={'/'} className={`${cx.custom} primary-button large`}>
            learn more about our packages
          </Link>
          <p className="subtitle-5">
            OR CALL <span>1.800.979.5799</span>
          </p>
        </div>
        <div className={`${cx.images}`}>
          <ElementImage
            key={1}
            src={extractImage(Ipad)}
            alt={'Sharlene Chang 2 Ipad'}
          />
        </div>
      </div>
    </section>
  )
}

export default RealEstateWebsites

import React from 'react'
import ElementImage from '@components/global/element/Image'
import { Link } from 'gatsby'

import * as cx from './Cards.module.scss'

const Cards = ({ images }) => {
  return (
    <section id="cards" className={`${cx.section}`}>
      <div className="container">
        <div className={`${cx.cardsWrapper}`}>
          <div className={`${cx.item}`}>
            <div className={`${cx.image}`}>
              <ElementImage src={images.card2} alt={`Card Image`} />
            </div>
            <div className={`${cx.content}`}>
              <h2 className={'subtitle-2'}>IDX Areas Covered</h2>
              <p className={'body-default large text-lg'}>
                Explore our full list of MLS Boards and Coverage your board or
                association
              </p>
              <Link to={'/'} className="primary-button large">
                Check coverage
              </Link>
            </div>
          </div>

          <div className={`${cx.item}`}>
            <div className={`${cx.image}`}>
              <ElementImage src={images.card1} alt={`Card Image`} />
            </div>
            <div className={`${cx.content}`}>
              <h2 className={'subtitle-2'}>IDX Consultation</h2>
              <p className={'body-default large text-lg'}>
                Learn more how you can benefit from our IDX
                <br />
                Solutions
              </p>
              <Link to={'/'} className="primary-button large">
                Get started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Cards

import React from 'react'
import PropTypes from 'prop-types'

import FormFreeConsultation from '../../FreeConsultation'
import ElementImageFile from '@components/global/element/ImageFile'
import * as cx from './LCF03.module.scss'
import clsx from 'clsx'

const LeadContentForm03 = ({
  background,
  model,
  title,
  subtitle,
  submitLabel,
  leadName,
  formLocation,
}) => {
  return (
    <section className={`${cx.wrapper} lcf03-wrapper`}>
      {background && (
        <ElementImageFile
          className={`${cx.wrapperBg} image-background lfc01-background`}
          src={background}
          alt={title}
          width={1440}
          height={880}
          lazy={true}
        />
      )}

      <div className={`${cx.holder} lcf03-holder`}>
        <div className={`${cx.model} lcf03-model`}>
          <ElementImageFile src={model} alt={title} lazy={true} />
        </div>

        <div className={`${cx.form} lcf03-form`}>
          <h3 className={clsx('subtitle-7 ')}>{subtitle}</h3>
          <h2 className="heading-5">{title}</h2>
          <FormFreeConsultation
            submitLabel={submitLabel}
            leadName={leadName}
            isEmailPhoneMerger={true}
            isMessageTextarea={true}
            formLocation={formLocation}
          />
        </div>
      </div>
    </section>
  )
}

LeadContentForm03.propTypes = {
  background: PropTypes.object,
  model: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  submitLabel: PropTypes.string,
  leadName: PropTypes.string,
  formLocation: PropTypes.string,
}

export default LeadContentForm03

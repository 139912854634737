// extracted by mini-css-extract-plugin
export var background = "IDXFeatures-module--background--74aef";
export var button = "IDXFeatures-module--button--8d9c3";
export var check = "IDXFeatures-module--check--56f20";
export var checkSvg = "IDXFeatures-module--check-svg--2e5c1";
export var container = "IDXFeatures-module--container--50a6f";
export var content = "IDXFeatures-module--content--608f2";
export var heading = "IDXFeatures-module--heading--0fb71";
export var image = "IDXFeatures-module--image--de8b2";
export var imageWrapper = "IDXFeatures-module--imageWrapper--d25af";
export var item = "IDXFeatures-module--item--bb063";
export var itemHeader = "IDXFeatures-module--itemHeader--88343";
export var itemWrapper = "IDXFeatures-module--itemWrapper--b7e84";
export var list = "IDXFeatures-module--list--a092b";
export var section = "IDXFeatures-module--section--2bbed";
export var subheading = "IDXFeatures-module--subheading--b4c92";
export var subtitle = "IDXFeatures-module--subtitle--64778";
import React, { useRef } from 'react'

import ElementImage from '@components/global/element/Image'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'
import SvgCheckedCircle from '@svg/checkedCircle.inline'

import useMedia, { media } from '@hooks/useMedia'
import { Link } from 'gatsby'

import * as cx from './IDXFeatures.module.scss'

const IDXFeatures = ({ images, icons, bg }) => {
  const modalFunc = useRef()
  return (
    <section id="idx-features" className={`${cx.section}`}>
      <ModalForm
        childFunc={modalFunc}
        title="Sign Up Today For A<br/>Free Consultation"
        description=""
        template={'m1'}
        type=""
      >
        <FormSignUp
          submitLabel="Get started today"
          leadName="HP Modal Form Sign Up"
        />
      </ModalForm>

      <div className={`${cx.heading} container`}>
        <h2 className="heading-2">IDX Features</h2>
        <p className={`${cx.subtitle} subtitle-1`}>FOR YOUR EVERY NEED</p>
      </div>
      <ul className={cx.list}>
        <li className={cx.itemWrapper}>
          <ElementImage className={cx.background} src={bg} alt={'BG'} />
          <div className={`container`}>
            <div className={cx.item}>
              <div className={cx.imageWrapper}>
                <div className={cx.image}>
                  <ElementImage src={images.idx1} alt={'Features Devices 1'} />
                </div>
              </div>
              <div className={cx.content}>
                <span>
                  <SvgCheckedCircle className={cx.checkSvg} />
                  <h2 className={`${cx.itemHeader} subtitle-2`}>Hotsheets</h2>
                </span>
                <p className={`${cx.subheading} small-heading`}>
                  Our Agent Image website content also supports custom forms to
                  improve branding and lead capture.
                </p>
                <p className={'subtitle-5'}>
                  For instance, you can use customized search pages to create
                  informative community pages. Showcase more than just the most
                  recent listings.
                </p>
                <p className={'subtitle-5'}>
                  With Agent Image's real estate agent websites, you can present
                  pertinent information and highlights for the areas you
                  represent. Visitors to your real estate agent website will
                  thank you for bringing them closer to your locale without
                  actually visiting it.
                </p>
                <div className={cx.button}>
                  <button
                    className="primary-button large"
                    onClick={(e) => modalFunc.current.openModal()}
                  >
                    learn more about our packages
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className={cx.itemWrapper}>
          <ElementImage className={cx.background} src={bg} alt={'BG'} />
          <div className={`container`}>
            <div className={cx.item}>
              <div className={cx.imageWrapper}>
                <div className={cx.image}>
                  <ElementImage src={images.idx2} alt={'Features Devices 2'} />
                </div>
              </div>
              <div className={cx.content}>
                <span>
                  <SvgCheckedCircle className={cx.checkSvg} />
                  <h2 className={`${cx.itemHeader} subtitle-2`}>
                    Robust Search Features
                  </h2>
                </span>
                <p className={`${cx.subheading} small-heading`}>
                  IDX, or Internet Data Exchange, is a policy that allows real
                  estate professionals to share property listing data across
                  their respective websites.
                </p>
                <p className={'subtitle-5'}>
                  {`Established by the National Association of Realtors (NAR), IDX uses information from local Multiple Listing Service (MLS) databases to feed comprehensive and up-to-date listings pages on agent and brokerage websites that cover a common area.`}
                </p>
                <div className={cx.button}>
                  <button
                    className="primary-button large"
                    onClick={(e) => modalFunc.current.openModal()}
                  >
                    learn more about our packages
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li className={cx.itemWrapper}>
          <ElementImage className={cx.background} src={bg} alt={'BG'} />
          <div className={`container`}>
            <div className={cx.item}>
              <div className={cx.imageWrapper}>
                <div className={cx.image}>
                  <ElementImage src={images.idx3} alt={'Features Devices 3'} />
                </div>
              </div>
              <div className={cx.content}>
                <span>
                  <SvgCheckedCircle className={cx.checkSvg} />
                  <h2 className={`${cx.itemHeader} subtitle-2`}>
                    Lead Capture Fields
                  </h2>
                </span>
                <p className={`${cx.subheading} small-heading`}>
                  At the end of the day, a real estate website is meant to
                  generate leads.
                </p>
                <p className={'subtitle-5'}>
                  Luckily, the best IDX platforms have registration forms,
                  pop-up notifications, and widgets that allow visitors to leave
                  their contact information so they can receive more details
                  about a listing or get property market reports. This way, you
                  can build up your lead database and have a steady supply of
                  prospects to nurture.
                </p>
                <div className={cx.button}>
                  <button
                    className="primary-button large"
                    onClick={(e) => modalFunc.current.openModal()}
                  >
                    learn more about our packages
                  </button>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </section>
  )
}

export default IDXFeatures

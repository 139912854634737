// extracted by mini-css-extract-plugin
export var background = "IDXSolutions-module--background--0905e";
export var bot = "IDXSolutions-module--bot--a92d2";
export var container = "IDXSolutions-module--container--45a49";
export var content = "IDXSolutions-module--content--25a65";
export var heading = "IDXSolutions-module--heading--c37b3";
export var iContent = "IDXSolutions-module--iContent--63dd4";
export var icon = "IDXSolutions-module--icon--86f6d";
export var imagecon = "IDXSolutions-module--imagecon--7d7b9";
export var item = "IDXSolutions-module--item--a6c39";
export var itemWrapper = "IDXSolutions-module--item-wrapper--6e26c";
export var list = "IDXSolutions-module--list--eda4f";
export var section = "IDXSolutions-module--section--5befb";
export var subtitle = "IDXSolutions-module--subtitle--69393";
export var wIcons = "IDXSolutions-module--wIcons--690ca";
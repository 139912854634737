import { useStaticQuery, graphql } from 'gatsby'

const useIDXWebsitesMetadata = () => {
  const query = useStaticQuery(graphql`
    query idxWebsiteMetadata {
      LaptopSrc: file(
        relativePath: { eq: "idx-websites/banner/plant-laptop.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      TabletSrc: file(
        relativePath: { eq: "idx-websites/banner/plant-tablet.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      PlantSrc: file(relativePath: { eq: "idx-websites/banner/plant.png" }) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      ZoomSrc: file(relativePath: { eq: "idx-websites/banner/magnified.png" }) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      ZoomMobileSrc: file(
        relativePath: { eq: "idx-websites/banner/magnified-mobile.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      BannerBG: file(
        relativePath: { eq: "idx-websites/banner/banner-bg.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXS1: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/idxs-1.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXS2: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/idxs-2.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXS3: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/idxs-3.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IAgent: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/iagent.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IBuyer: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/ibuyer.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      ISeller: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/iseller.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      SBG1: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/sbg-1.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      SBG2: file(
        relativePath: { eq: "idx-websites/idx-solutions-images/sbg-2.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXF1: file(
        relativePath: { eq: "idx-websites/idx-features-images/idxf-1.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXF2: file(
        relativePath: { eq: "idx-websites/idx-features-images/idxf-2.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXF3: file(
        relativePath: { eq: "idx-websites/idx-features-images/idxf-3.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      IDXF3: file(
        relativePath: { eq: "idx-websites/idx-features-images/idxf-3.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      ICheck: file(
        relativePath: { eq: "idx-websites/idx-features-images/i-check.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      FBG: file(
        relativePath: { eq: "idx-websites/idx-features-images/fbg.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      Card1: file(relativePath: { eq: "idx-websites/cards/card-1.png" }) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      Card2: file(relativePath: { eq: "idx-websites/cards/card-2.png" }) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      FaqBG: file(relativePath: { eq: "idx-websites/faqs/faq-bg.png" }) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      PlaceholderLaptop: file(
        relativePath: { eq: "idx-websites/banner/placeholder-laptop.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      Ipad: file(
        relativePath: { eq: "idx-websites/real-estate/2-ipad-sharlene.png" }
      ) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
      Model: file(relativePath: { eq: "idx-websites/consultation/model.png" }) {
        id
        ...idxWebsiteMetadataImageOptimize
      }
    }

    fragment idxWebsiteMetadataImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useIDXWebsitesMetadata

import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { useContactFormMetadata } from '@hooks/contact-form-metadata'
import useMedia, { media } from '@hooks/useMedia'

import Seo from '@components/seo/Index'
import Banner from '@components/global/banner/Banner'
import RealEstateWebsites from '@components/pages/idx-websites/RealEstateWebsites'
import IDXSolutions from '@components/pages/idx-websites/IDXSolutions'
import IDXFeatures from '@components/pages/idx-websites/IDXFeatures'
import Cards from '@components/pages/idx-websites/Cards'
import Faqs from '@components/global/accordions/Faqs'
import LeadContentForm03 from '@components/global/forms/LeadContactForm/LCF03'
import LayoutInnerPage from '@src/layouts/InnerPage'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'

import * as cx from './IdxWebsites.module.scss'

import useIDXWebsitesMetadata from '../../hooks/idx-websites-metadata'

const IDXWebsites = ({}) => {
  const query = useContactFormMetadata()
  const isTablet = useMedia(media.tablet)
  const isPhone = useMedia(media.phone)
  const {
    LaptopSrc,
    BannerBG,
    PlantSrc,
    TabletSrc,
    ZoomSrc,
    ZoomMobileSrc,
    IDXS1,
    IDXS2,
    IDXS3,
    IAgent,
    IBuyer,
    ISeller,
    SBG1,
    SBG2,
    IDXF1,
    IDXF2,
    IDXF3,
    FBG,
    ICheck,
    Card1,
    Card2,
    FaqBG,
    Model,
    PlaceholderLaptop,
  } = useIDXWebsitesMetadata()

  const tempBreadcrumbs = [
    { text: 'Home', url: '/' },
    { text: 'IDX Real Estate Websites', url: '/idx-websites/' },
  ]
  const faqsData = [
    {
      question: 'Is IDX the same as MLS?',
      answer: [
        'IDX is a more powerful version of multiple listing services (MLS). Basically, MLS is a way to upload, manage, and share listings from a specific area. It is generally offered only to real estate agents.',
        'An MLS listing includes photos, descriptions, special features, and any other details the real estate agent needs to know to show the property. For your real estate website to present MLS listings properly, it must have IDX.',
        'IDX allows anyone with an internet connection to access listings. In addition, IDX is connected to the local MLS. This software is updated more frequently than other listing sites, so an IDX website offers the most recent information on every listing, including current prices.',
      ],
    },
    {
      question: 'How Can Agent Image Help?',
      answer: [
        "Agent Image creates beautiful real estate websites that offer the most current technology that makes lead generation easier. We can also enhance your marketing efforts with powerful website content that can build your reputation as your market's go-to expert.",
        'Our beautifully designed websites and professionally written blogs are SEO-compliant so your site can dominate internet search rankings. We can also help you run pay-per-click (PPC) ad campaigns to supercharge your lead generation. All our websites work on any screen size, so it looks and functions beautifully on desktops, tablets, and smartphones.',
      ],
    },
    {
      question: 'What Does a Great IDX Real Estate Website Include?',
      featureAnswer: [
        [
          'You may have a website, but is it usable and user-friendly?',
          'With our help, your website will be intuitive and easily navigable, leaving visitors impressed and interested.',
        ],
        [
          "Every Agent Image website is created with a real estate agent's needs in mind.",
          "That's why they include the following vital features:",
        ],
      ],
      features: [
        {
          title: 'Neighborhood Information',
          content:
            'This page tackles everything that makes a locale a great neighborhood, such as annual events, schools, restaurants, shopping centers, and recreation activities.',
        },
        {
          title: 'Listings',
          content:
            "Listings are the heart of a real estate agent's business. Help potential clients find their new home through your agent website with updated listings.",
        },
        {
          title: 'Filtered Searches',
          content:
            'Give buyers more freedom by allowing them to input preferred home specs, like the number of bedrooms/bathrooms, price range, amenities, etc. You can then track which features are most in-demand and highlight such listings.',
        },
        {
          title: 'Lead Capture Landing Pages',
          content:
            'Make it easy for clients to contact you! Every page includes your phone number, email, or contact forms.',
        },
        {
          title: 'About You',
          content:
            'Let people know who you are and how you can help them to find or sell their homes. Show your personality and explain why they should choose you, what they can expect from you, and what your specialties are.',
        },
        {
          title: 'Interactive Map Search',
          content:
            'When peop le are looking to buy real estate, they want to see the neighborhood and local roads. An interactive map will let them see where the home is and what is nearby. Our sites offer interactive maps to help your client find the perfect home on the perfect street.',
        },
        {
          title: 'Other Pages',
          content:
            'These are not the only pages we can develop and design for you . Take a look at the custom packages below to see how we can build an agent website that suits your every need.',
        },
      ],
    },
  ]

  return (
    <LayoutInnerPage>
      <Banner
        wrapperClassName={cx.banner}
        layout="product-gallery"
        formLayout="default"
        bgImage={extractImage(BannerBG)}
        breadcrumbs={tempBreadcrumbs}
        title={
          isPhone
            ? `IDX Solutions<br/>for Agents and<br/>Brokers`
            : `IDX Solutions for Agents and Brokers`
        } //page.title
        titleClassName={`${cx.customhead} heading-2`}
        description="REAL ESTATE MLS PROPERTY SEARCH"
        descriptionClassName="subtitle-1 neutral-gray"
        formHeadingClassName={`${cx.formheadb} subtitle-6`}
        buttonClassName={
          isTablet ? 'primary-button small' : 'primary-button large'
        }
        alignBannerAndLogo={true}
        devices={{
          layout: 'PlantLaptopTablet',
          laptop: extractImage(LaptopSrc),
          tablet: extractImage(TabletSrc),
          zoom:
            isTablet || isPhone
              ? extractImage(ZoomMobileSrc)
              : extractImage(ZoomSrc),
          plant: extractImage(PlantSrc),
          laptopPlaceholder: extractImage(PlaceholderLaptop),
        }}
        formWrapperClass={cx.form}
        formHeadClass={cx.formhead}
        containerClassName={cx.container}
        breadcrumbsClassName={cx.breadcrumbs}
      />
      <RealEstateWebsites />
      <IDXSolutions
        images={{
          idx1: extractImage(IDXS1),
          idx2: extractImage(IDXS2),
          idx3: extractImage(IDXS3),
        }}
        icons={{
          agent: extractImage(IAgent),
          buyer: extractImage(IBuyer),
          seller: extractImage(ISeller),
        }}
        bg={{
          bg1: extractImage(SBG1),
          bg2: extractImage(SBG2),
        }}
      />
      <IDXFeatures
        images={{
          idx1: extractImage(IDXF1),
          idx2: extractImage(IDXF2),
          idx3: extractImage(IDXF3),
        }}
        icons={extractImage(ICheck)}
        bg={extractImage(FBG)}
      />
      <Cards
        images={{
          card1: extractImage(Card1),
          card2: extractImage(Card2),
        }}
      />
      <Faqs
        noZoomEffect
        liftContent
        extendTitleSpace
        bgImg={extractImage(FaqBG)}
        items={faqsData}
        wrapperClassName={cx.faqs}
        imageHeight={702}
      />
      <LeadContentForm03
        biggerSubtitle
        model={getImage(Model)}
        title="Free Consultation"
        subtitle="Get In Touch With Us For A"
        submitLabel="I want a stunning website!"
      />
    </LayoutInnerPage>
  )
}

export default IDXWebsites
